import React, { Component, ErrorInfo } from 'react';
import { EmptyState, Page, TextButton, WixDesignSystemProvider } from "@wix/design-system"

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error: ', error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <WixDesignSystemProvider>
        <Page>
          <Page.Content>
            <EmptyState
              theme="page"
              title="Something went wrong"
              subtitle="Please refresh the page or try again later"
            >
              <TextButton onClick={() => window.location.reload()}>Refresh</TextButton>
              {/* contact support */}
              <TextButton as="a" href="mailto:team@studiobase.global" target="_blank">Contact Support</TextButton>
            </EmptyState>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;