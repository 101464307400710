import React, { useEffect } from "react";
import {
  Loader,
  Heading,
  Modal,
  MessageModalLayout,
  Text,
  Card,
  Box,
  Page,
  Button,
  Cell,
  Layout,
  WixDesignSystemProvider,
  AnnouncementModalLayout,
  FormField,
  Input,
  InputArea,
  SectionHelper,
  Notification,
  ToggleSwitch,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";
import * as Sentry from "@sentry/react";
import ErrorBoundary from "./Components/ErrorBondary";

/* eslint-disable @typescript-eslint/no-unused-expressions, @typescript-eslint/no-unused-vars */
import * as locale from "locale-codes";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const supported_locale = [
  "ar",
  "bn",
  "bg",
  "zh",
  "hr",
  "cs",
  "da",
  "nl",
  "en",
  "et",
  "fi",
  "fr",
  "de",
  "el",
  "iw",
  "hi",
  "hu",
  "id",
  "it",
  "ja",
  "ko",
  "lv",
  "lt",
  "no",
  "pl",
  "pt",
  "ro",
  "ru",
  "sr",
  "sk",
  "sl",
  "es",
  "sw",
  "sv",
  "th",
  "tr",
  "uk",
  "vi",
];

function App() {
  ReactTagManager.init(tagManagerArgs);

  const [screen, setScreen] = React.useState("");
  const [agentPrompt, setAgentPrompt] = React.useState("");
  const [agentLocale, setAgentLocale] = React.useState("en");
  const [agentChoices, setAgentChoices] = React.useState([] as any);
  const [showRefreshBanner, setShowRefreshBanner] = React.useState(false);

  /* Wix Owner App - Start */
  const isOwnerApp =
    new URLSearchParams(window.location.search).get("openedIn") === "ownerApp";
  const isPremiumUpgradeDisallowed =
    new URLSearchParams(window.location.search).get(
      "isPremiumUpgradeAllowed"
    ) === "false";
  /* Wix Owner App - End */
  const [showVideo, setShowVideo] = React.useState(false);
  const [embedId, setEmbedId] = React.useState("");
  const [videoName, setVideoName] = React.useState("");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(true);
  const [settings, setSettings] = React.useState({
    isEnabled: false,
    agent: "",
  } as any);

  const [instanceData, setInstanceData] = React.useState({} as any);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
  }, []);

  useEffect(() => {
    if (settings?.shouldSave) {
      settings.shouldSave = undefined;
      saveSettings();
    }
  }, [settings]);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=813d8957-1e50-42af-8974-e8b521a0a7d9&redirectUrl=https://studiobaseapps.wixsite.com/chatbot/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  if (!!token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const BASE_URL = `https://studiobaseapps.wixsite.com/chatbot/_functions`;

  const YoutubeEmbed = ({ embedId }: any) => (
    <iframe
      width="560px"
      height="315px"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  );

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.email) {
          Sentry.setUser({ email: data.email });
          window._cio.identify({
            id: data.email,
            email: data.email,
          });
        }
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        Sentry.setUser({ id: data.instance.id });
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
        setAgentLocale(data.site.locale);
      })
      .catch(() => {
        setIsModalOpen(true);
      });
  }

  function saveSettings() {
    setIsSaving(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify(settings),
    })
      .then((response) => response.json())
      .then((data) => {
        setSettings(data);
        setIsSaving(false);
      })
      .catch(() => {
        setIsSaving(false);
      });
  }

  function createAgent() {
    setIsSaving(true);
    fetch(BASE_URL + "/agents", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        prompt: agentPrompt,
        lang: agentLocale,
      }),
    })
      .then((response) => response.json())
      .then(({ options }) => {
        console.log(options);
        setAgentChoices(options);
        setIsSaving(false);
      })
      .catch(() => {
        setIsSaving(false);
      });
  }

  function getSettings() {
    setIsSaving(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setScreen(data?.isEnabled ? "" : "setup");
        setSettings(data);
        setIsSaving(false);
      })
      .catch(() => {
        setScreen("setup");
        setIsSaving(false);
      });
  }

  class ChatAppMissing extends React.Component {
    render() {
      return (
        <AnnouncementModalLayout
          title="Install Wix Chat to start using Chat - GPT based conversations"
          primaryButtonText="Install now"
          primaryButtonOnClick={() => {
            window.open(
              `https://manage.wix.com/dashboard/${
                (instanceData as any)?.site?.siteId
              }/app-market/web-solution/wix-chat`
            );
          }}
        >
          <Text>
            Wix Chat is a free app that lets you chat with your visitors and
            customers. It's easy to install and use.
          </Text>
        </AnnouncementModalLayout>
      );
    }
  }

  const getUpgradeHint = () => {
    if ((instanceData as any)?.instance?.billing?.billingCycle === "YEARLY") {
      return "Thank you for your subscription!";
    }
    if ((instanceData as any)?.instance?.billing?.billingCycle === "MONTHLY") {
      return "Save $36/year";
    }
    return "Unlimited Conversations";
  };

  const getUpgradeText = () => {
    if ((instanceData as any)?.instance?.billing?.billingCycle === "YEARLY") {
      return "Manage Plan";
    }
    if ((instanceData as any)?.instance?.billing?.billingCycle === "MONTHLY") {
      return "Switch to yearly";
    }
    return "Activate AI Agent";
  };

  var isWixChatMissing = !(
    (instanceData as any)?.site?.installedWixApps || ["chat"]
  ).includes("chat");

  // if (showSetupExperience) {
  //   return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
  //     <Page height='100vh'>
  //       <Page.Header title='Setup Chat: GPT-based conversations'
  //       actionsBar={
  //         <Button skin="inverted" onClick={()=>{
  //           setShowSetupExperience(false);
  //         }}>Skip Setup</Button>
  //       }
  //       ></Page.Header>
  //       <Page.Content>
  //         <Layout>
  //           <Cell><Stepper
  //             fit="compact"
  //             activeStep={0}
  //             steps={[{ text: 'Choose Integration Solution' }, { text: 'Review Settings' }, { text: 'Try it out!' }]}
  //           /></Cell>
  //           <Cell><Card>
  //             <Card.Content>
  //               <Box direction="vertical" gap="SP2">
  //                 <SectionHelper appearance="preview">Choose one to setup first. You can add/remove later.</SectionHelper>
  //                 <Box direction="horizontal" gap="SP2">
  //                   <Thumbnail
  //                     title="Live Chat"
  //                     description="A chat widget (Wix Chat) that pinned to the bottom of your site"
  //                     image={<Image width="200px" src={LiveChat} />}
  //                     height={300}
  //                     contentAlignment="center"
  //                     width={400}
  //                   />
  //                   <Thumbnail
  //                     title="Q&A Widget"
  //                     description="A widget placed inline with your site content, with customizable persona on each widget"
  //                     image={<Image width="200px" src={ChatWidget} />}
  //                     height={300}
  //                     contentAlignment="center"
  //                     width={400}
  //                   />
  //                 </Box>
  //               </Box>
  //             </Card.Content>
  //           </Card></Cell>
  //           <Cell><Card>
  //             <Card.Content>
  //               <Box gap="SP3">
  //                 <Avatar color="A2" name={'marcelmeier'} />
  //                 <Box direction="vertical" gap="6px">
  //                   <Box>
  //                     <Text size="small">marcelmeier </Text>
  //                     <Text size="small" secondary>
  //                       / Jun 28, 2023
  //                     </Text>
  //                   </Box>
  //                   <StarsRatingBar value={5} readOnly size="small" />
  //                   <Text weight="bold">Amazingly smart and friendly</Text>
  //                   <Text weight="thin">
  //                     We have completed the WIX FAQ app and activated ChatGPT in WIXChat. After a few initial stumbles, ChatGPT now understands a lot of customer questions and prepares the information so well that our customers receive the answers they are looking for quickly, friendly and competently. We are amazed and also pleased that ChatGPT is constantly learning and is very accommodating and interacts with visitors.
  //                   </Text>
  //                 </Box>
  //               </Box>
  //             </Card.Content>
  //           </Card></Cell>
  //         </Layout>
  //       </Page.Content>
  //     </Page>
  //   </WixDesignSystemProvider>
  // }

  return (
    <ErrorBoundary>
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Notification
          show={showRefreshBanner}
          onClose={() => {
            setShowRefreshBanner(false);
          }}
          theme="standard"
        >
          <Notification.TextLabel>
            Upgraded to Premium? Refresh the page to start using your AI agent.
          </Notification.TextLabel>
          <Notification.ActionButton
            onClick={() => {
              setInstance();
              setShowRefreshBanner(false);
            }}
          >
            Refresh
          </Notification.ActionButton>
        </Notification>
        <Page height="100vh" minWidth={0}>
          <Page.Header
            title={
              <Box gap={"SP2"} alignItems="middle">
                <Heading>
                  {agentChoices.length > 0
                    ? "Choose your AI Agent"
                    : "Manage AI Expert"}
                </Heading>
              </Box>
            }
            actionsBar={
              <Box direction="horizontal" gap="SP1">
                {isUpgraded ? (
                  <Button
                    prefixIcon={<Icons.Reviews />}
                    skin="inverted"
                    as="a"
                    href="https://bridget.reviews.certifiedcode.us/813d8957-1e50-42af-8974-e8b521a0a7d9"
                    target="_blank"
                  >
                    Rate App
                  </Button>
                ) : (
                  <></>
                )}
                <Box direction="vertical">
                  <Button
                    skin="premium"
                    prefixIcon={<Icons.PremiumFilled />}
                    onClick={() => {
                      window.open(
                        `https://www.wix.com/apps/upgrade/${"813d8957-1e50-42af-8974-e8b521a0a7d9"}?appInstanceId=${
                          instanceData?.instance?.instanceId
                        }`
                      );
                      setShowRefreshBanner(true);
                    }}
                  >
                    {getUpgradeText()}
                  </Button>
                  {/* <Badge size='tiny' skin="neutralLight">{getUpgradeHint()}</Badge> */}
                </Box>
              </Box>
            }
          />
          <Page.Content>
            <Modal isOpen={showVideo}>
              <AnnouncementModalLayout
                title={videoName}
                onCloseButtonClick={() => {
                  setShowVideo(false);
                }}
              >
                <YoutubeEmbed embedId={embedId} />
              </AnnouncementModalLayout>
            </Modal>
            <Modal isOpen={isWixChatMissing} shouldCloseOnOverlayClick={false}>
              {<ChatAppMissing></ChatAppMissing>}
            </Modal>
            <Modal isOpen={isModalOpen} screen="desktop">
              <MessageModalLayout
                primaryButtonText="Refresh"
                primaryButtonOnClick={() => window.location.reload()}
                secondaryButtonText="Contact Support"
                secondaryButtonOnClick={() => {
                  window.Intercom(
                    "showNewMessages",
                    "I need help accessing the app dashboard."
                  );
                }}
                title="Something wrong happened!"
                content={
                  <Text>
                    Please access this app via Wix dashboard, or reinstall the
                    app if issue persist.
                  </Text>
                }
              />
            </Modal>
            <Layout>
              {isSaving && screen !== "" ? (
                <Cell span={12}>
                  <Box
                    height={"90vh"}
                    direction="vertical"
                    verticalAlign="middle"
                  >
                    <Loader
                      text="We are setting up your AI agent"
                      size="large"
                    />
                  </Box>
                </Cell>
              ) : null}
              {agentChoices.map((v: string) => {
                return (
                  <Cell span={4}>
                    <Box direction="vertical" gap={"SP1"}>
                      <InputArea
                        autoGrow={true}
                        maxHeight={"400px"}
                        value={v}
                        readOnly
                      />
                      <Button
                        fullWidth
                        size="large"
                        disabled={isSaving}
                        skin={isUpgraded ? "ai" : "premium"}
                        prefixIcon={
                          isUpgraded ? <Icons.Check /> : <Icons.PremiumFilled />
                        }
                        onClick={() => {
                          setScreen("");
                          setAgentChoices([]);
                          setSettings({
                            isEnabled: true,
                            agent: v,
                            shouldSave: true,
                          });
                        }}
                      >
                        Hire this Agent
                      </Button>
                    </Box>
                  </Cell>
                );
              })}
              {agentChoices.length > 0 ? (
                <Cell span={12}>
                  <Box direction="vertical" verticalAlign="middle" gap="SP2">
                    <Button
                      onClick={() => {
                        setAgentChoices([]);
                      }}
                    >
                      I don't like any of these agents
                    </Button>
                    <Text size="small">
                      After you hire an agent, you can edit it later.
                    </Text>
                  </Box>
                </Cell>
              ) : null}
              {screen === "setup" && !isSaving && agentChoices.length === 0 ? (
                <Cell span={12}>
                  <Card>
                    <Card.Header title="Setup your AI Agent" />
                    <Card.Content>
                      <Layout>
                        <Cell span={12}>
                          <FormField
                            label="What do you want your AI agent do for your site visitors?"
                            status={
                              // if too short, give warning
                              agentPrompt.length > 0 && agentPrompt.length < 50
                                ? "warning"
                                : undefined
                            }
                            statusMessage={
                              agentPrompt.length > 0 && agentPrompt.length < 50
                                ? "Write a longer description to help your AI agent understand your site better"
                                : undefined
                            }
                          >
                            <InputArea
                              minHeight="200px"
                              onChange={(e) => {
                                setAgentPrompt(e.target.value);
                              }}
                              placeholder="Describe your site in 1-2 sentences"
                            />
                          </FormField>
                        </Cell>
                        <Cell span={12}>
                          <FormField
                            label="What is your site language?"
                            infoContent="Type a locale code (e.g. en, fr, de) or language name (e.g. English, French, German)"
                            status={
                              agentLocale.length > 0 &&
                              !(
                                supported_locale.includes(agentLocale) ||
                                supported_locale.includes(
                                  locale.where("name", agentLocale)?.tag
                                )
                              )
                                ? "warning"
                                : undefined
                            }
                            statusMessage={
                              agentLocale.length > 0 &&
                              !(
                                supported_locale.includes(agentLocale) ||
                                supported_locale.includes(
                                  locale.where("name", agentLocale)?.tag
                                )
                              )
                                ? "We are still working on this language. Compatibility is not guaranteed."
                                : undefined
                            }
                          >
                            <Input
                              value={agentLocale}
                              onChange={(e) => {
                                setAgentLocale(e.target.value);
                              }}
                              placeholder="English"
                            />
                          </FormField>
                        </Cell>
                        <Cell span={12}>
                          <Button
                            fullWidth
                            size="large"
                            disabled={isSaving || agentPrompt.length === 0}
                            skin="ai"
                            prefixIcon={<Icons.AIFilled />}
                            onClick={() => {
                              createAgent();
                            }}
                          >
                            {isSaving ? <Loader size="tiny" /> : "Create Agent"}
                          </Button>
                        </Cell>
                      </Layout>
                    </Card.Content>
                  </Card>
                </Cell>
              ) : null}

              {screen === "" ? (
                <Cell span={12}>
                  {isUpgraded ? (
                    <SectionHelper appearance="preview">
                      Your AI agent is now live on your site. You can edit it
                      anytime. To try it out, send a message via the Wix Chat
                      widget on your site.
                    </SectionHelper>
                  ) : (
                    <SectionHelper
                      appearance="danger"
                      actionText="Upgrade Now"
                      onAction={() => {
                        window.open(
                          `https://www.wix.com/apps/upgrade/${"813d8957-1e50-42af-8974-e8b521a0a7d9"}?appInstanceId=${
                            instanceData?.instance?.instanceId
                          }`
                        );
                        setShowRefreshBanner(true);
                      }}
                    >
                      To use your AI agent on the live site, please upgrade Chat app to a premium plan.
                    </SectionHelper>
                  )}
                </Cell>
              ) : null}
              {screen === "" ? (
                <Cell span={12}>
                  <Card>
                    <Card.Header
                      title="Your AI Agent"
                      subtitle="Edit your AI agent"
                      suffix={
                        <ToggleSwitch
                          checked={settings?.isEnabled}
                          disabled={isSaving}
                          onChange={(v) => {
                            console.log(v);
                            if (isUpgraded || v.target.checked === false) {
                              setSettings({
                                ...settings,
                                isEnabled: v.target.checked,
                                shouldSave: true,
                              });
                            } else {
                              window.open(
                                `https://www.wix.com/apps/upgrade/${"813d8957-1e50-42af-8974-e8b521a0a7d9"}?appInstanceId=${
                                  instanceData?.instance?.instanceId
                                }`
                              );
                              setShowRefreshBanner(true);
                            }
                          }}
                        />
                      }
                    />
                    <Card.Divider />
                    <Card.Content>
                      <FormField label="Update your AI agent">
                        <Layout>
                          <Cell span={12}>
                            <InputArea
                              status={isSaving ? "loading" : undefined}
                              statusMessage={
                                isSaving
                                  ? "We are updating your AI agent."
                                  : undefined
                              }
                              autoGrow={true}
                              // maxHeight={"400px"}
                              value={settings?.agent}
                              onChange={(e) => {
                                setSettings({
                                  ...settings,
                                  agent: e.target.value,
                                  shouldSave: true,
                                });
                              }}
                            />
                          </Cell>
                          <Cell span={12}>
                            <Text size="tiny" skin="primary">
                              Changes are autosaved.
                            </Text>
                          </Cell>
                        </Layout>
                      </FormField>
                    </Card.Content>
                  </Card>
                </Cell>
              ) : null}
            </Layout>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    </ErrorBoundary>
  );
}

export default App;
